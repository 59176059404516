import { ReactNode, createContext } from 'react'

export const LocaleContext = createContext<{
  readonly locale: string
  readonly messages: Record<string, string>
}>({
  locale: 'en',
  messages: {},
})

export const LocaleProvider: React.FC<{
  locale: string
  messages: Record<string, string>
  children: ReactNode
}> = ({ locale, messages, children }) => {
  return (
    <LocaleContext.Provider value={{ locale, messages }}>
      {children}
    </LocaleContext.Provider>
  )
}
