import { useContext } from 'react'

import { LocaleContext } from './context'

type TranslateFunction = (
  key: string,
  values?: Record<string, string>
) => string
type Return = {
  i18n: TranslateFunction
  locale: string
}

export const useTranslation = (): Return => {
  const { locale, messages } = useContext(LocaleContext)

  if (!locale || !messages) {
    throw new Error('Page i18n props missing!.')
  }

  const i18n: TranslateFunction = (key, values = {}) => {
    if (!messages[key]) {
      if (process.env.NODE_ENV === 'development') {
        throw new Error(
          `Translation '${key}' for locale '${locale}' not found.`
        )
      } else {
        return key
      }
    }

    const message = messages[key]

    const pattern = /\{\{(.*?)\}\}/gim
    const matches = message.match(pattern)
    const parts = message.split(pattern)

    if (!matches) {
      return message
    }

    for (const match of matches) {
      const matchName = match.replace(/\{\{|\}\}/gim, '')
      if (!values[matchName]) {
        if (process.env.NODE_ENV === 'development') {
          throw new Error(
            `Missing value: \`${matchName}\` for translation key: \`${key}\``
          )
        } else {
          values[matchName] = matchName
        }
      }
    }

    const elements = parts.map((part) => {
      return values[part] || part
    })

    return Array.isArray(elements) ? elements.join('') : elements
  }

  return {
    i18n,
    locale,
  }
}
