import { css, FlattenSimpleInterpolation } from 'styled-components'

export const focusOutline = (color: string): FlattenSimpleInterpolation => {
  return css`
    // Target browsers that do not support :focus-visible
    &:focus {
      box-shadow: 0 0 0 3px ${addAlphaToColor(color, 30)};
    }

    // Reset focus for browsers that support
    &:focus:not(:focus-visible) {
      box-shadow: none;
    }

    // Style for focus only when needed
    &:focus-visible {
      box-shadow: 0 0 0 3px ${addAlphaToColor(color, 30)};
    }
  `
}

export const addAlphaToColor = (
  color: string,
  percentageInDecimal = 50
): string => {
  if (percentageInDecimal === 100) {
    return color
  }

  const cleanColor = color.replace(/\s/gim, '')

  // regex
  if (/^#[0-9A-F]{6}$/gim.test(cleanColor)) {
    return `${cleanColor}${Math.round((percentageInDecimal * 255) / 100)
      .toString(16)
      .toUpperCase()
      .padStart(2, '0')}`
  }

  // 3 digit regex
  if (/^#[0-9A-F]{3}$/gim.test(cleanColor)) {
    const sixDigitRegex = cleanColor.replace(
      /#([0-9a-fA-F])([0-9a-fA-F])([0-9a-fA-F])/gim,
      '#$1$1$2$2$3$3'
    )
    return `${sixDigitRegex}${Math.round((percentageInDecimal * 255) / 100)
      .toString(16)
      .toUpperCase()
      .padStart(2, '0')}`
  }

  // rgb
  if (/^rgb\(\d{1,3},\d{1,3},\d{1,3}\)$/gim.test(cleanColor)) {
    return cleanColor
      .replace('rgb', 'rgba')
      .replace(/\)$/gim, `,0.${percentageInDecimal})`)
  }

  // rgba
  if (/^rgba\(\d{1,3},\d{1,3},\d{1,3},\d+(?:.?\d+?)?\)$/gim.test(cleanColor)) {
    const [r, g, b, a] = cleanColor.split(',')
    const newAlpha = percentageInDecimal * Number(a.replace(')', ''))
    return `${r},${g},${b},0.${newAlpha})`
  }

  throw new Error(
    `Unexpected color received: ${color}\nThis function only works with 6 digits HEX, rgb and rgba colors`
  )
}

// given a colors object and an accessor like 'foreground.default', returns the color
export const getColorFromObject = (
  obj: Record<string, unknown>,
  accessor: string
): string | void => {
  const [key, ...rest] = accessor.split('.')

  if (!obj[key]) {
    throw new Error('Invalid color path. Please check your theme.')
  }

  if (typeof obj[key] === 'string') {
    return obj[key] as string
  }

  if (typeof obj[key] === 'object' && obj[key] !== null) {
    return getColorFromObject(
      obj[key] as Record<string, unknown>,
      rest.join('.')
    )
  }
}

export const dashedLine = (
  position: 'left' | 'right' | 'bottom' | 'top',
  color: string
): FlattenSimpleInterpolation => {
  const orientation =
    position === 'left' || position === 'right' ? 'vertical' : 'horizontal'

  return css`
    background-image: linear-gradient(
      ${orientation === 'vertical' ? '0deg' : '90deg'},
      ${color},
      ${color} 40%,
      transparent 40%,
      transparent 100%
    );
    background-size: ${orientation === 'vertical' ? '1px 6px' : '6px 1px'};
    background-position: ${orientation === 'vertical'
      ? `${position} top`
      : `left ${position}`};
    background-repeat: ${orientation === 'vertical' ? 'repeat-y' : 'repeat-x'};
  `
}
