import { AppProps } from 'next/app'
import Router, { useRouter } from 'next/router'
import NProgress from 'nprogress'
import { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { ThemeProvider } from 'styled-components'

import { GTM_IDS } from 'common/constants'
import { theme } from 'common/theme'
import { GlobalStyle } from 'common/theme/GlobalStyle'
import { LocaleProvider } from 'lib/i18n'

import '../global.css'

NProgress.configure({ showSpinner: false })
Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

const CustomApp: React.FC<AppProps> = ({ Component, pageProps }) => {
  const { asPath } = useRouter()

  for (let index = 0; index < GTM_IDS.length; index++) {
    const dataLayerNamePrefix = index === 0 ? '' : index.toString()

    useEffect(function initGtm() {
      TagManager.initialize({
        gtmId: GTM_IDS[index],
        dataLayerName: `dataLayer${dataLayerNamePrefix}`,
      })
    }, [])
  }

  useEffect(
    function gtmPageData() {
      TagManager.dataLayer({
        dataLayer: {
          originalLocation:
            document.location.protocol +
            '//' +
            document.location.hostname +
            document.location.pathname +
            document.location.search,
        },
      })
    },
    [asPath]
  )

  return (
    <LocaleProvider
      locale={pageProps.locale || 'en'}
      messages={pageProps.messages}
    >
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Component {...pageProps} />
      </ThemeProvider>
    </LocaleProvider>
  )
}

export default CustomApp
