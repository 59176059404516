// theme type defined at @types/styled.d.ts
import { DefaultTheme } from 'styled-components'

import { lightTheme } from './themes'

type Breakpoints = keyof DefaultTheme['breakpoints']
type Media = Record<Breakpoints, string>

export const breakpoints: Media = {
  zero: '0',
  sm: '30em',
  md: '48em',
  lg: '64em',
  xl: '80em',
  xxl: '105em',
}

const media = Object.keys(breakpoints).reduce<Media>((acc, key) => {
  return {
    ...acc,
    [key]: `@media screen and (min-width: ${breakpoints[key as Breakpoints]})`,
  }
}, {} as Media)

export const theme: DefaultTheme = {
  colors: lightTheme,
  transitions: {
    ease: (time = '100ms'): string => `${time} ease-in-out`,
    cubic: (time = '300ms'): string => `${time} cubic-bezier(0.2, 1, 0.2, 1)`,
  },
  breakpoints,
  media,
  shadows: {
    subtle: '0 1px 1px 0 rgba(0, 0, 0, 0.04)',
    small: '0 2px 4px 0 rgba(0, 0, 0, 0.04)',
    medium: '0 1px 2px 0 rgba(0, 0, 0, 0.05), 0 6px 12px 0 rgba(0, 0, 0, 0.05)',
    high: '0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 8px 16px 0 rgba(0, 0, 0, 0.1)',
  },
  zIndex: {
    low: 10,
    mid: 100,
    high: 500,
    highest: 999,
  },
  typography: {
    bodyFontSize: 16,
    families: {
      sans: `'GT America', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
      serif: `'Wulkan', serif`,
    },
  },
}
