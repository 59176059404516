import { Fragment, useContext } from 'react'

import { LocaleContext } from './context'

type Props = {
  id: string
  values?: Record<string, string | React.ReactNode>
}

export const FormattedMessage = ({ id, values = {} }: Props): JSX.Element => {
  const { locale, messages } = useContext(LocaleContext)

  if (!locale || !messages) {
    throw new Error('Page i18n props missing!')
  }

  if (!messages[id]) {
    if (process.env.NODE_ENV === 'development') {
      throw new Error(`Translation '${id}' for locale '${locale}' not found.`)
    } else {
      messages[id] = id
    }
  }

  const message = messages[id]

  const pattern = /\{\{(.*?)\}\}/gim
  const matches = message.match(pattern)
  const parts = message.split(pattern)

  if (!matches) {
    return <Fragment>{message}</Fragment>
  }

  for (const match of matches) {
    const matchName = match.replace(/\{\{|\}\}/gim, '')
    if (!values[matchName]) {
      if (process.env.NODE_ENV === 'development') {
        throw new Error(
          `Missing value: \`${matchName}\` for translation key: \`${id}\``
        )
      } else {
        values[matchName] = matchName
      }
    }
  }

  const elements = parts.map((part, i) => {
    return <Fragment key={part + i}>{values[part] || part}</Fragment>
  })

  return <Fragment>{elements}</Fragment>
}
