import pkg from '../../package.json'

export const SITE_ORIGIN =
  process.env.NEXT_PUBLIC_ORIGIN || 'https://tfp-fertility.com/'

export const GTM_IDS = ['GTM-M2RNLSF']

export const CONFIG_FOLDER = '_config'

export const MODAL_ROOT_ID = 'tfp-ui-modal'

export const ASSET_FOLDER_ID = 51042

export const APP_VERSION = pkg.version

export const SENTRY_DSN =
  process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

// classname that is going to be picked up by the dynamic phone number script to update the phone number shown
export const DYNAMIC_PHONE_NUMBER_CLASSNAME = 'floating_button'

export const MAPBOX_STYLE =
  'mapbox://styles/tfp-fertility/cl4ztsbba003a14qlx6vyb80b'
