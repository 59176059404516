import { DefaultTheme } from 'styled-components'

export const palette: DefaultTheme['colors']['palette'] = {
  white: '#FFFFFF',
  black: '#000000',
  brand: {
    default: '#245A80',
    100: '#EDEDEB',
    200: '#E3E6E6',
    300: '#CED6DA',
    350: '#C5CED3',
    400: '#A3B7C4',
    500: '#7998AD',
    550: '#6A8DA4',
    600: '#316486',
    700: '#245A80',
    800: '#1F4D6E',
    900: '#0F334C',
  },
  blue: {
    100: '#EDEEEB',
    200: '#E3E6E6',
    300: '#CED6DA',
    350: '#C5CED3',
    400: '#A3B7C4',
    500: '#7998AD',
    550: '#6A8DA4',
    600: '#316486',
    700: '#245A80',
    800: '#1F4D6E',
    900: '#0F334C',
  },
  yellow: {
    default: '#F5E5BC',
    light: '#F7EFDC',
    lightDark: '#F4E8CD',
    dark: '#F2DEAB',
  },
  orange: {
    default: '#F2D2C5',
    light: '#F6E7E0',
    lightDark: '#F1DAD0',
    dark: '#EEC5B4',
  },
  pink: {
    light: '#F6EAEB',
    default: '#F2D9E0',
    100: '#F4EBEB',
    200: '#F3DDE3',
    300: '#F2D9E0',
    350: '#EDCAD4',
    400: '#FEB4CD',
    500: '#E08BA8',
    550: '#DA7294',
    600: '#C56890',
    700: '#AB4478',
    800: '#88225F',
    900: '#660045',
  },
  aqua: {
    default: '#C5D8D6',
    dark: '#B9D0CD',
    light: '#EAECE9',
  },
  violet: {
    light: '#EDE7E8',
    wild: '#DCD2DC',
  },
  hyacinth: {
    light: '#EDECED',
    default: '#D7D9E2',
  },
  purple: {
    default: '#660045',
    light: '#AB4478',
  },
  green: {
    default: '#D1E3D2',
    light: '#E8EDE8',
  },
  lime: {
    default: '#DBECBD',
    light: '#EBF0E1',
  },
  gradient: {
    yellow: '#FCEFB5',
    coral: '#F5D2D1',
    aqua: '#BADAD6',
    lavender: '#D5C8D6',
  },
  beige: {
    default: '#F8F5F1',
    80: 'rgba(248, 245, 241, 0.8)',
    60: 'rgba(248, 245, 241, 0.6)',
    40: 'rgba(248, 245, 241, 0.4)',
    20: 'rgba(248, 245, 241, 0.2)',
    10: 'rgba(248, 245, 241, 0.1)',
    5: 'rgba(248, 245, 241, 0.05)',
  },
}
