import { palette } from './palette'

export const lightTheme = {
  foreground: {
    default: palette.brand.default,
    subtle: palette.blue[600],
    secondary: palette.blue[800],
    dark: palette.blue[900],
  },
  background: {
    default: palette.beige.default,
    subtle: palette.white,
    secondary: palette.pink.light,
    secondaryPink: palette.pink.default,
    secondaryBlue: palette.blue[300],
    secondaryLightYellow: palette.yellow.light,
    secondaryYellow: palette.yellow.default,
    secondaryLightOrange: palette.orange.light,
    secondaryOrange: palette.orange.default,
    secondaryAqua: palette.aqua.default,
    secondaryLightAqua: palette.aqua.light,
    dark: palette.brand[100],
    secondaryLightWildViolet: palette.violet.light,
    secondaryWildViolet: palette.violet.wild,
    secondaryLightHyacinth: palette.hyacinth.light,
    secondaryHyacinth: palette.hyacinth.default,
  },
  accent: {
    default: palette.beige.default,
    subtle: palette.blue[900],
    secondary: palette.purple.light,
    dark: palette.purple.default,
  },
  ui: {
    error: palette.orange.default,
    warning: palette.yellow.default,
    success: palette.green.default,
  },
  icon: {
    default: palette.brand.default,
  },
  palette,
}

export const lightOrangeTheme = {
  ...lightTheme,
  icon: {
    default: '#E67A5C',
  },
  background: {
    ...lightTheme.background,
    default: palette.pink.light,
    subtle: '#FBC8B9',
    secondary: palette.white,
    dark: palette.white,
  },
}

export const lightGreyTheme = {
  ...lightTheme,
  background: {
    ...lightTheme.background,
    default: palette.brand[100],
    subtle: palette.brand[300],
    secondary: palette.white,
    dark: palette.white,
  },
}

export const darkBlueTheme = {
  foreground: {
    default: palette.beige.default,
    subtle: palette.blue[600],
    secondary: palette.blue[800],
    dark: palette.blue[900],
  },
  background: {
    default: palette.blue[700],
    subtle: palette.brand[600],
    secondary: palette.pink.default,
    secondaryPink: palette.pink[350],
    secondaryBlue: palette.blue[350],
    secondaryLightYellow: palette.yellow.lightDark,
    secondaryYellow: palette.yellow.dark,
    secondaryLightOrange: palette.orange.lightDark,
    secondaryOrange: palette.orange.dark,
    secondaryAqua: palette.aqua.dark,
    secondaryLightAqua: palette.aqua.light,
    dark: palette.blue[800],
    secondaryLightWildViolet: palette.violet.light,
    secondaryWildViolet: palette.violet.wild,
    secondaryLightHyacinth: palette.hyacinth.light,
    secondaryHyacinth: palette.hyacinth.default,
  },
  accent: {
    default: palette.blue[700],
    subtle: palette.green.light,
    secondary: palette.pink[400],
    dark: palette.pink[500],
  },
  ui: {
    error: palette.orange.default,
    warning: palette.yellow.default,
    success: palette.green.default,
  },
  icon: {
    default: palette.beige.default,
  },
  palette,
}

export const darkPurpleTheme = {
  ...darkBlueTheme,
  foreground: {
    default: palette.beige.default,
    subtle: palette.beige[40],
    secondary: palette.beige[60],
    dark: palette.beige[80],
  },
  background: {
    ...lightTheme.background,
    default: palette.purple.light,
    subtle: palette.purple.default,
    secondary: palette.white,
    dark: palette.white,
  },
}
