import { createGlobalStyle } from 'styled-components'

import { nprogress } from './nprogress'
import { reset } from './reset'

export const GlobalStyle = createGlobalStyle`
  ${reset};
  ${nprogress}
  
  body {
    background-color: ${({ theme }) => theme.colors.background.default};
    color: ${({ theme }) => theme.colors.foreground.default};

    font-family: ${({ theme }) => theme.typography.families.sans};
    font-size: ${({ theme }) => theme.typography.bodyFontSize + 'px'};
    font-weight: 300;
    
    font-smooth: always;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    color: ${({ theme }) => theme.colors.accent.default};
  }

  * {
    box-sizing: border-box;
  }

  .tfp-highlight {
    position: relative;
    background-color: transparent;
    color: ${({ theme }) => theme.colors.palette.purple.default};
  }

  .ot-floating-button {
    bottom: 60px !important;

    ${({ theme }) => theme.media.md} {
      bottom: 1.5rem !important;
    }
  }


`
