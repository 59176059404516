import { css } from 'styled-components'

import { addAlphaToColor } from 'common/utils/style'

export const nprogress = css`
  #nprogress {
    pointer-events: none;
  }
  #nprogress .bar {
    background: ${({ theme }) => theme.colors.palette.pink.default};
    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
  }
  #nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: ${({ theme }) =>
      `0 0 10px ${addAlphaToColor(
        theme.colors.palette.pink.default,
        80
      )}, 0 0 5px ${addAlphaToColor(theme.colors.palette.pink.default, 80)}`};
    opacity: 1;
    -webkit-transform: rotate(3deg) translate(0px, -4px);
    -ms-transform: rotate(3deg) translate(0px, -4px);
    transform: rotate(3deg) translate(0px, -4px);
  }
  #nprogress .spinner {
    display: 'block';
    position: fixed;
    z-index: 1031;
    top: 15px;
    right: 15px;
  }
  #nprogress .spinner-icon {
    width: 18px;
    height: 18px;
    box-sizing: border-box;
    border: solid 3px transparent;
    border-top-color: ${({ theme }) => theme.colors.palette.pink.default};
    border-left-color: ${({ theme }) => theme.colors.palette.pink.default};
    border-radius: 50%;
    -webkit-animation: nprogresss-spinner 400ms linear infinite;
    animation: nprogress-spinner 400ms linear infinite;
  }
  .nprogress-custom-parent {
    overflow: hidden;
    position: relative;
  }
  .nprogress-custom-parent #nprogress .spinner,
  .nprogress-custom-parent #nprogress .bar {
    position: absolute;
  }
  @-webkit-keyframes nprogress-spinner {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes nprogress-spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
